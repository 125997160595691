<template>
  <div>
    <div class="p-4">
      <div class="vx-row BgWhite p-4 items-center">
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Order No: {{ order.id }}
        </div>
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Created: {{ GetRelativeTime(order.created) }}
        </div>

        <div class="vx-col flex md:w-1/3 sm:w-1/2 w-full">
          <div class="mr-4">Order Status:</div>
          <vs-chip :color="GetOrderStatusColor(order.order_status)">{{
            order.order_status_display
          }}</vs-chip>
        </div>

        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Total Gold ({{ selectedGoldWeightTypeStore.weightType }}) :
          {{
            (order.total_volume * selectedGoldWeightTypeStore.value).toFixed(2)
          }}
        </div>

        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Total Resin/Credit: {{ order.total_weight }}
        </div>

        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Machine Type : {{ order.machine_type }}
        </div>
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          <vs-button
            v-if="order.order_status == 'W'"
            color="success"
            class="pt-2 pb-2 pl-3 pr-3"
            @click="CheckoutOrder"
          >
            Checkout
          </vs-button>
        </div>
      </div>
    </div>

    <div class="vx-row m-0" v-if="plates.length">
      <div class="w-full m-2" style="font-size: 1.2rem; font-weight: 600">
        Plates
      </div>
      <div
        class="vx-col m-0 p-0 lg:w-1/4"
        v-for="plate in getPlatesList"
        :key="plate.id"
      >
        <plateDetail
          :plate="plate"
          :orderId="orderId"
          :machineType="order.machine_type"
        />
      </div>
      <div v-if="plates.length > 8" class="flex justify-center w-full mt-2">
        <vs-button @click="showAllPlates = !showAllPlates">
          {{ showAllPlates ? "Hide Plates" : "Show All Plates" }}
        </vs-button>
      </div>
    </div>

    <div class="w-full m-2" style="font-size: 1.2rem; font-weight: 600">
      Products
    </div>

    <div class="vx-row">
      <div
        class="vx-col md:w-1/4 sm:w-1/2 w-full"
        v-for="productGroup in productGroups"
        :key="productGroup.id"
      >
        <div class="vx-row p-2 mt-2 ml-1 mr-1 BgWhite">
          <div class="vx-col w-full">
            <swiper
              :options="swiperOption"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              :key="$vs.rtl"
            >
              <swiper-slide
                v-for="image in productGroup.images"
                :key="image.id"
              >
                <div
                  class="flex items-center"
                  :style="'height:' + maxImageHeight + 'px'"
                >
                  <img
                    ref="image"
                    @load="SetMaxHeight"
                    class="popupImage"
                    :src="image.image_thumbnail"
                    alt="banner"
                  />
                </div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
          <div class="vx-col pr-2 pl-2 w-full">
            <div
              style="font-size: 1.1rem; color: #000; cursor: pointer"
              @click="OpenProductGroup(productGroup.id)"
            >
              {{ GetTitleFromProductGroup(productGroup.id) }}
            </div>

            <div
              class="flex justify-between pr-2"
              style="cursor: pointer; font-weight: 500; font-size: 0.9rem"
              @click="OpenProductGroup(productGroup.id)"
            >
              <div>
                Resin/Credit: {{ GetTotalProductGroupWeight(productGroup.id) }}
              </div>
              <div class="">
                Gold ({{ selectedGoldWeightTypeStore.weightType }}) :
                {{
                  (
                    GetTotalProductGroupVolume(productGroup.id) *
                    selectedGoldWeightTypeStore.value
                  ).toFixed(2)
                }}
              </div>
            </div>

            <vs-icon
              class="flex justify-center w-full"
              v-if="productGroup.id == expandedDetail"
              color="#D1D1D1"
              icon="expand_less"
              style="cursor: pointer"
              size="large"
              @click="expandedDetail = null"
            >
            </vs-icon>

            <vs-icon
              class="flex justify-center mt-6 w-full"
              v-else
              color="#D1D1D1"
              icon="expand_more"
              style="cursor: pointer"
              size="large"
              @click="ExpandedOpen(productGroup.id)"
            >
            </vs-icon>
          </div>
        </div>
        <div
          class="detailExpand pr-4 pl-4 pb-4 mr-1 ml-1"
          v-if="expandedDetail == productGroup.id"
        >
          <vs-divider class="m-0 p-0 mb-2"></vs-divider>

          <div
            class="vx-row justify-between pr-4 pl-4 mt-1"
            v-for="item in GetItemsWithProductGroupId(productGroup.id)"
            :key="item.id"
          >
            <div>
              {{ item.product_title }}
            </div>
            <div>
              {{ item.quantity }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import moment from "moment";
import debounce from "debounce";

import plateDetail from "./components/OrderPlateDetail.vue";

import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  data() {
    return {
      showAllPlates: false,
      plates: [],
      orderId: null,
      maxImageHeight: 0,
      expandedDetail: null,
      order: {},
      productGroups: [],
      swiperOption: {
        lazy: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    OpenProductGroup(id) {
      let params = {
        search: this.GetTitleFromProductGroup(id),
      };
      this.$router.push({
        name: "nokta_market",
        query: params,
      });
    },
    CheckoutOrder() {
      var params = {};
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      params.order_status = "N";
      Api.patch(
        API.BASEURL + API.NOKTA_MARKET_ORDERS + this.orderId + "/",
        this.HandleCheckoutOrder,
        params
      );
    },
    HandleCheckoutOrder(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.$vs.notify({
          title: "Success",
          text: "Order has been checked out",
          color: "success",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.GetOrderDetail();
        this.$store.dispatch("decreaseNoktaMarketCredit", data.total_weight);
      } else if (status == 400) {
        this.$vs.notify({
          color: "warning",
          title: "Warning",
          text: data.data.order_status[0],
        });
      }
    },
    Resize() {
      this.maxImageHeight = this.$refs.image[0].clientHeight;
    },
    SetMaxHeight(event) {
      let div = event.target;
      if (div.clientHeight > this.maxImageHeight) {
        this.maxImageHeight = div.clientHeight;
      }
    },
    ExpandedOpen(id) {
      if (this.expandedDetail == id) {
        this.expandedDetail = null;
      } else {
        this.expandedDetail = id;
      }
    },
    GetTotalProductGroupWeight(id) {
      let totalWeight = 0;
      this.order.items.forEach((item) => {
        if (id == item.product_group) {
          totalWeight += item.product_weight * item.quantity;
        }
      });
      return totalWeight.toFixed(2);
    },
    GetTotalProductGroupVolume(id) {
      let totalVolume = 0;
      this.order.items.forEach((item) => {
        if (id == item.product_group) {
          totalVolume += item.product_volume * item.quantity;
        }
      });
      return totalVolume;
    },
    GetTitleFromProductGroup(id) {
      for (let index = 0; index < this.order.items.length; index++) {
        const element = this.order.items[index];
        if (element.product_group == id) {
          return element.product_group_title;
        }
      }
    },
    GetItemsWithProductGroupId(id) {
      return this.order.items.filter((item) => item.product_group == id);
    },
    GetOrderStatusColor(status) {
      if (status == "P") return "warning";
      if (status == "R") return "success";
      if (status == "C") return "danger";
      if (status == "W") return "#389CA3";
      return "primary";
    },
    GetRelativeTime(value) {
      return moment(String(value)).format("DD/MM/YYYY - HH:mm");
    },
    GetOrderDetail(loading = true) {
      if (loading) {
        this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      }

      Api.get(
        API.BASEURL + API.NOKTA_MARKET_ORDERS + this.orderId + "/",
        this.HandleGetOrderDetail
      );
    },
    HandleGetOrderDetail(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.order = data;
        this.plates = data.plates;

        if (data.order_status == "N" || data.order_status == "P") {
          setTimeout(() => {
            this.GetOrderDetail(false);
          }, 10000);
        }

        if (data.images.length) {
          this.SetProductGroupsWithImages(data.images);
        }
      }
    },
    SetProductGroupsWithImages(images) {
      this.productGroups = [];
      for (let index = 0; index < images.length; index++) {
        const element = images[index];

        let indexE = this.productGroups.findIndex(
          (x) => x.id == element.product_group
        );

        if (indexE == -1) {
          this.productGroups.push({
            id: element.product_group,
            images: [
              {
                image: element.image,
                image_thumbnail: element.image_thumbnail,
                order: element.order,
              },
            ],
          });
        } else {
          this.productGroups[indexE].images.push({
            image: element.image,
            image_thumbnail: element.image_thumbnail,
            order: element.order,
          });
        }
      }
    },
  },

  computed: {
    getPlatesList() {
      if (!this.showAllPlates && this.plates.length > 8) {
        return this.plates.slice(0, 8);
      } else {
        return this.plates;
      }
    },
    selectedGoldWeightTypeStore() {
      return this.$store.getters["noktaMarket/selectedWeightMultipier"];
    },
  },

  components: {
    swiper,
    swiperSlide,
    plateDetail,
  },

  created() {
    if (this.$route.query.id) {
      this.orderId = this.$route.query.id;
      this.GetOrderDetail();
    }
    window.onresize = debounce(this.Resize, 50);
  },
  beforeDestroy() {
    window.onresize = null;
  },
};
</script>

<style scoped>
.detailExpand {
  background-color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 0.8rem;
}
.BgWhite {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: 600;
}
.popupImage {
  width: 100%;
  height: auto;
}
</style>
