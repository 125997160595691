<template>
  <div class="BgWhite p-4 m-2">
    <div class="flex justify-between">
      <p>Name: {{ plate.plate }}</p>
      <p>Qty: {{ plate.quantity }}</p>
    </div>
    <div class="flex justify-between p-2">
      <vs-icon
        style="cursor: pointer"
        icon="info"
        size="small"
        color="dark"
        @click="showDetail ? (showDetail = false) : ShowDetail()"
      ></vs-icon>
      <vs-icon
        style="cursor: pointer"
        :icon="downloadIcon"
        size="small"
        :color="downloadButtonColor"
        @click="
          downloadEnable = true;
          GetPlateDetail();
        "
      ></vs-icon>
    </div>
    <vs-progress
      class="mt-2"
      v-if="filePersent && filePersent != 100"
      :height="12"
      :percent="filePersent"
    ></vs-progress>

    <div v-if="showDetail">
      <VuePerfectScrollbar
        class="plateDetailScroll"
        :settings="plateDetailScrollSettings"
        @ps-scroll-y="ScrollHandle"
      >
        <div
          v-for="(item, index) in plateDetail.plate_items_detail"
          :key="item.product_title"
          class="flex justify-between pl-2 pr-4"
          :style="index % 2 == 0 ? { background: '#f5f5f5' } : {}"
        >
          <div>
            {{ item.product_title }}
          </div>
          <div>
            {{ item.quantity_in_plate }}
          </div>
        </div>
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import VuePerfectScrollbar from "vue-perfect-scrollbar";

import axios from "axios";

export default {
  props: ["plate", "orderId", "machineType"],
  data() {
    return {
      plateDetail: null,
      showDetail: false,
      downloadEnable: false,
      request: axios.CancelToken.source(),
      filePersent: 0,
      downloadIcon: "download",
      downloadButtonColor: "dark",
      plateDetailScrollSettings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
      },
    };
  },
  methods: {
    ScrollHandle(event) {
      if (event.cancelable) {
        event.preventDefault();
      }
    },
    ShowDetail() {
      if (this.plateDetail == null) {
        this.GetPlateDetail();
      } else {
        this.showDetail = true;
      }
    },
    DownloadPlate(url) {
      this.downloadEnable = false;
      this.downloadIcon = "clear";
      this.downloadButtonColor = "danger";

      this.request = axios.CancelToken.source();

      axios({
        url: url,
        method: "GET",
        cancelToken: this.request.token,
        responseType: "blob",
        onDownloadProgress: function (progressEvent) {
          this.filePersent = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then((response) => {
          this.downloadIcon = "download";
          this.downloadButtonColor = "dark";
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            this.orderId +
              "_" +
              this.plate.plate +
              "_" +
              this.machineType.replace(/\s/g, "") +
              ".nova"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          if (error.message == "Cancel") {
            this.filePersent = 0;
          }
        });
    },
    GetPlateDetail() {
      if (this.downloadButtonColor == "danger") {
        this.RequestCancel();
        return;
      }
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      Api.get(
        API.BASEURL +
          API.NOKTA_MARKET_ORDERS +
          this.orderId +
          "/plates/" +
          this.plate.id +
          "/",
        this.HandleGetPlateDetail
      );
    },
    HandleGetPlateDetail(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.plateDetail = data;
        if (this.downloadEnable) {
          this.DownloadPlate(data.plate_model_file);
        } else {
          if (!this.showDetail) {
            this.showDetail = true;
          }
        }
      }
    },
    RequestCancel() {
      this.request.cancel("Cancel");
      this.downloadIcon = "download";
      this.downloadButtonColor = "dark";
    },
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style scoped>
.BgWhite {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-weight: 600;
}
.plateDetailScroll {
  max-height: 200px;
}
</style>
